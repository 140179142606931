import axios from "axios";

const state = {
  metafield: {}
};

const getters = {
  metafieldDetails: (state) => state.metafield
};

const actions = {
  async getMetafields({ commit }, variant_id) {
    if (variant_id) {
      const metafields = await axios.get(`${process.env.VUE_APP_API_URL}` + "/metafield/" + `${variant_id}`);
      if (!metafields.data) { return false }
      const metafieldData = metafields && metafields.data;
      commit("setMetafield", metafieldData);
      return metafieldData;
    }

  },
  async createMetafield({ commit }, values) {
    if (values) {
      const metafield = await axios.post(`${process.env.VUE_APP_API_URL}` + "/metafield", { "variant_id": values.id, "date": values.date });
      const metafieldData = metafield && metafield.data;
      if (!metafieldData) {
        return false;
      }
      commit("setMetafield", metafield);
      return metafield;

    }
  },
  async updateMetafield({ commit }, updateValues) {
    const date = new Date(updateValues['metafield.value']);
    var month = date.toLocaleString('default', { month: 'long' });
    var dd = date.getDate();var yy = date.getFullYear();var field = month+' '+dd+', '+yy;
    const updateMetafield = await axios.put(`${process.env.VUE_APP_API_URL}` + "/metafield/" + `${updateValues.metafield.legacyResourceId}`, { "date": field },{
      headers: {
        'x-auth-token': updateValues.token
      }});
    const metafieldData = updateMetafield && updateMetafield.data;
    if (!metafieldData) { return false; }
    commit("setMetafield", metafieldData);
    return metafieldData;
  },
  async clearMetafieldApi(_, data) {
    const updateMetafield = await axios.post(`${process.env.VUE_APP_API_URL}` + "/metafield/clear/" + `${data.id}`, { "date": 0 },{
      headers: {
        'x-auth-token': data.token
      }});
    const metafieldData = updateMetafield && updateMetafield.data;
    if (!metafieldData) { return false; }
    return metafieldData;
  },
  async bulkUpdateMetafield(_,updateValues) {
      console.log("Upload_Data",updateValues);
    const result =  await axios.post(`${process.env.VUE_APP_API_URL}` + "/metafield/bulk-update", { "data": updateValues.data},{
        headers: {
          'x-auth-token': updateValues.token
        }}).then((updateMetafield) => {
          return updateMetafield
        }).catch((err)=>{
            return err
        });
        return result;
    }
};

const mutations = {
  setMetafield(state, data) {
    state.metafield = data;
  },
  setVariantsByvendor(state,data){
    state.exportedVariants = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

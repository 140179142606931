import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import shop from "./modules/shop";
import inevntory from "./modules/inventory";
import metafield from "./modules/metafield";
import { createStore } from "vuex";

// Create store
const store = createStore({
    modules: {
      auth,
      shop,
      metafield,
      inevntory
    },
    plugins: [createPersistedState()],
});

export default store;
<template>
    <div id="topheader" v-bind:class="(isLoggedIn)?'logged':'login'">
         <div class="header">
                <div class="header_container">
                  <div class="logo_in">
                            <a href="/">
                                <img class="logo" src="../assets/cclogo.svg" alt="">
                            </a>    
                    </div>
                        <div v-if="isLoggedIn" class="headerShop">
                            <span>Shop: </span> <span>{{shop?.details?.name}}</span>
                            <Button class="btn" icon="pi pi-external-link" label="Logout" @click="logout($event)" />
                        </div>
                </div>
                <div id="nav">
                    <ul v-if="isLoggedIn">
                        <!-- <li><a href="/products">Products</a></li> -->
                        <li><a href="/vendors">Vendors</a></li>
                        <!-- <li><a href="/inventory-variants">Variants</a></li> -->
                        <li><a href="/export">Export</a></li>
                        <li><a href="/upload">Upload</a></li>
                    </ul>
                </div>
            </div>
    </div>  
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import Button from 'primevue/button';
export default {
  name: 'topheader',
   components: {
        Button
    },
    methods: {
           ...mapActions(["LogIn","LogOut"]),
         ...mapGetters(["isAuthenticated","shopDetails"]), 
        logout:async function (e){
             e.preventDefault();
             await this.LogOut();
        }
    },
    mounted: function () {
       
    },
    computed: {
       isLoggedIn : function(){
          return this.$store.getters.isAuthenticated
      },
     shop: function(){
          return this.$store.getters.shopDetails
      }
    }
}
      
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button#Download_all_domains_Csv {
    float: left;
    margin: 20px 0 0 0;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    color: #169e35;
    background: transparent;
    border-color: #169e35;
    cursor: pointer;
    border: 2px solid #169e35;
}
section#form_data {
    max-width: 1280px;
    padding: 20px;
    box-shadow: 0px 0px 10px #f1f1f1;
    margin: 0 auto;
    border-radius: 10px;
    display: inline-block;
    width: 100%;

}
.logo {
    width: 300px;
}
h3.heading {
    font-size: 26px;
    margin-bottom: 5px;
    color: #000;
    text-transform: capitalize;
}
.domains {
    float: left;
    width: 50%;
}
textarea.textarea_urls {
    height: 260px;
    width: 100%;
    padding: 15px 20px;
    max-width: 1025px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

form.form_domain textarea {
    margin: 0 0 20px 0;
    border: 1px solid #000 !important;
    box-shadow: none  !important;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #000 !important;
    font-size: 17px;
    font-weight: normal;
}

form.form_domain textarea::-webkit-input-placeholder {
   color: #000 !important;
}
form.form_domain textarea::-moz-placeholder { 
  color: #000 !important;
}
form.form_domain textarea:-ms-input-placeholder { 
   color: #000 !important;
}
form.form_domain textarea:-moz-placeholder {
  color: #000 !important;
}


div#results * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

div#results {
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
    max-width: 1024px;
    margin: 0 auto;
}

div#results div.domains {
    width: 100% !important;
    float: none !important;
    
}

table.domains {
   border: none;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 20px auto 0 auto;
    overflow: visible;
}

table.domains tr th, table.domains tr td {
    border: 1px solid #000;
    padding:15px;
    text-align: left;
}


div#results button#DownloadCsv {
    float: right;
    margin: 20px 0 0 0;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
}

h3.heading {
    background: #097fc8;
    color: #fff !important;
    width: 100%;
    max-width: 1025px;
    margin: 50px auto 0 !important;
    padding: 8px;
    font-size: 23px !important;
    box-sizing: border-box;
}

table.domains tr th {
    background: #097fc8;
    color:#fff;
}


button.btn.btn-primary {
    padding: 15px 10%;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    background: #169e35;
    color: #fff;
    text-transform: uppercase;
    display: block;
    margin: 15px auto;
}
button#DownloadCsv {
    padding: 15px 30px;
    font-size: 20px;
    background: transparent;
    border: 2px solid #169e35;
    border-radius: 10px;
    color: #169e35;
    cursor: pointer;
}
table.domains {
    width: 100%;
 }
table.subdomains {
    width: 100%;
    max-width: 50%;
}
table, th, td {
  border:1px solid black;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

div#loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.5);
    z-index: 9;
    height: 100vh;
    display: -webkit-box; 
    display: -moz-box; 
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


</style>

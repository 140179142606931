import axios from "axios";

const state = {
  inevntory: {}
};

const getters = {
    inevntoryDetails: (state) => state.inevntory
};

const actions = {
    async getInevntory({commit},data) {
          const inevntorys = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/inventory",{
            "limit":200,
            "pageKey":data.pageKey?data.pageKey:null
        },{headers:{
          'x-auth-token': data.token
        }});
          if(!inevntorys.data){return false}
          const inevntoryData = inevntorys && inevntorys;
          commit("setInevntoryVariants", inevntoryData);
          return inevntoryData;
    }
};

const mutations = {
    setInevntoryVariants(state, data){
        state.inevntory = data;
    }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import axios from "axios";

const state = {
  shop: {},
  products: {},
  brands: {},
  brandsCount: {},
  variants:{}
};

const getters = {
  shopDetails: (state) => state.shop
};

const actions = {
  async shop({commit},password) {

    const shopDetail = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/details",{"password":password},{
      headers: {
        'x-auth-token': password
      }
    });
    const shop = shopDetail && shopDetail.data;
     if (!shop){
        return false;
    }
    commit("setShopDetails", shop);
    return shop;
  },
  async products({commit},token) {
    const products = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/products",{"limit":50,"pageNo":1},{
      headers:{
        'x-auth-token': token
      }
    });
    const productsData = products && products.data;
     if (!productsData){
        return false;
    }
    commit("setProducts", products.data);
    return products.data;
  },
  async variants({commit},data) {
    const {id,token} = data;
    const variants = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/products/"+`${id}`+"/variants",null,{
      headers:{
        'x-auth-token': token
      }
    });
    const variantsData = variants && variants.data;
     if (!variantsData){
        return false;
    }
    commit("setVariants", variants.data);
    return variants.data;
  },
  async getBrands({commit},token) {
    const brands = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/vendor-list",null,{
      headers: {
        'x-auth-token': token
      }
    });
    const brandsData = brands && brands.data;
     if (!brandsData){
        return false;
    }
    commit("setShopBrands", brandsData);
    return brandsData;
  },
  async getVariantsByVendorList(_,Vendordata) {
    console.log("Vendordata",Vendordata)
    const getVariants = await axios.post(`${process.env.VUE_APP_API_URL}` + "/shop/variants-by-vendors", { "vendors": Vendordata.vendors},{headers:{
        "x-auth-token":Vendordata.token
    }});
    // .then(function (response){
    //   return response;
    // }).catch(function (error) {
    //   if (error.response) {
    //     return error.response
    //     // console.log("error.response",error);
    //   }
    // });
    return getVariants;
  },
  async getCountByvendor({commit},data) {
    const count = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/brands/"`+${data.vendor}/count`,{"password":data.password});
    const vendorCount = count && count.data.count;
     if (!vendorCount){
        return false;
    }
    commit("setShopBrandsCount", vendorCount);
    return count;
  },
  async getVendorVariants({commit},data) {
    const variants = await axios.post(`${process.env.VUE_APP_API_URL}`+"/shop/brands/",{
      "vendor":data.vendor
    },{
      headers: {
        'x-auth-token': data.token
      }
    }).catch(function (error) {
      console.log("error",error);
      if(error){return {status:503,message:"Something went wrong please try in few mintunes!"}}
    });
    const variantsData = variants && variants.data;
     if (variantsData && variantsData.message){
        return {status:404,message:"No variation of the inventory showed that the stock quantity was 1200."};
    }
    commit("setVendorVariants", variantsData);
    return variantsData;
  }
};

const mutations = {
  setShopDetails(state,data){
    state.shop = data;
  },
  setProducts(state,data){
    state.products = data;
  },
  setVariants(state,data){
    state.products = data;
  },
  setShopBrands(state,data){
    state.brands = data;
  },
  setShopBrandsCount(state,data){
    state.brandsCount = data;
  },
  setVendorVariants(state,data){
    state.brandsCount = data;
  },
//   logout(state, user) {
//     state.user = user;
//   },
//   setError(state,error){
//       state.error = error;
//   }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
<!-- <HomePageversion2 /> -->
<div id="main_body">
    <topheader></topheader>
    <router-view />
</div>
</template>

<script>
import topheader from '../src/common/header.vue';


export default {
  name: 'App',
  components: {
    topheader
  }
}
</script>
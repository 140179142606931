import App from "./App.vue";
import router from "./router";
import store from './store'
import PrimeVue from 'primevue/config';
import { createApp } from "vue";


import "../src/assets/global.css";
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/rhea/theme.css';
createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(store)
  .mount("#app");

// import Vue from "vue";
import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Homepage",
    component: () => import('../views/pages/Home.vue'),
  },
  {
    path: "/mystore",
    name: "Mystore",
    component: () => import('../views/pages/mystore.vue'),
  },
  {
    path: "/products",
    name: "products",
    component: () => import('../views/pages/products.vue'),
  },
  {
    path: "/vendors",
    name: "productsBrands",
    component: () => import('../views/pages/brands.vue'),
  },
  {
    path: "/vendors/vendor-variants/:vendor",
    name: "VendorVariants",
    component: () => import('../views/pages/vendor-variants.vue'),
    props: true
  },
  {
    path: "/products/:id/variants",
    name: "Variants",
    component: () => import('../views/pages/variants.vue'),
    props: true
  },
  {
    path: "/products/variants/metafield/:id/",
    name: "Metafield",
    component: () => import('../views/pages/metafield.vue'),
    props: true
  },
  {
    path: "/inventory-variants",
    name: "inevntory-variants",
    component: () => import('../views/pages/inventory-variants.vue'),
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import('../views/pages/upload.vue'),
  },
  {
    path: "/export",
    name: "export",
    component: () => import('../views/pages/export.vue'),
  },
  {
     path: '/:pathMatch(.*)*',  
    name: 'Page404',
    component: () => import('../views/pages/404.vue'), },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
export default router;

import axios from "axios";

const state = {
  user: false,
  error:null,
  token:null
};

const getters = {
  isAuthenticated: (state) => state.user,
  StateError:(state) => state.error,
  getUserToken:(state) => state.token
};

const actions = {
  async LogIn({commit},password) {
    const auth = await axios.post(`${process.env.VUE_APP_API_URL}`+"/login",{"password":password}).catch(function (error) {
       return error});
    const isLogin = auth && auth.data && auth.data.status;
    if (!isLogin){
        return false;
    }
    
    if(auth.data.token){
        commit("setlogin", isLogin);
        commit("setUserToken", auth.data.token);
    }
    
  },
  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
    commit("setUserToken", null);
    commit("setShopDetails", null);
  },
  async updateError({commit},error){
    commit("setError", error);
  }
};

const mutations = {
  setlogin(state,data){
    state.user = data;
  },
  logout(state, user) {
    state.user = user;
  },
  setError(state,error){
      state.error = error;
  },
  setUserToken(state,password){
    state.token = password;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
